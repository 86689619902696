@use "../abstracts"as *;

.nngn {
  padding-top: 0;
  padding-left: min(vw(170), 170px);
  min-height: unset;

  h2 {
    font-size: min(vw(82), 82px);
  }

  .stafftalk002 {
    width: min(vw(300));
    height: auto;
    margin-top: min(vw(-70), -70px);
  }

  .ign {
    margin-top: min(vw(-60), -60px);
    margin-bottom: min(vw(-40), -40px);
    padding-right: min(vw(120), 120px);

    .stafftalk001 {
      width: min(vw(842));
    }
  }
}

.a0gg3.ds9dg {
  min-height: unset;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  gap: min(vw(100), 100px);
  padding-top: min(vw(100), 100px);
  padding-bottom: min(vw(100), 100px);

  .n93 {
    &:nth-child(1) {
      width: min(vw(450), 450px);

      img {
        width: 100%;
        height: auto;
      }

      .enn {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        padding-top: min(vw(60), 60px);
        height: 100%;

        p {
          font-weight: bold;
          writing-mode: vertical-rl;
          height: 100%;
          font-size: min(vw(110), 110px);
        }
      }
    }

    &:nth-child(2) {
      width: calc(100% - min(vw(450), 450px));

      .d8g4d {
        display: flex;
        gap: min(vw(40), 40px);
        padding-bottom: min(vw(30), 30px);
        padding-right: min(vw(60), 60px);
        border-bottom: min(vw(8), 8px) solid #82FF00;

        p {
          &:nth-child(1) {
            font-size: min(vw(126), 126px);
            font-style: italic;
            font-weight: bold;
          }

          &:nth-child(2) {
            font-weight: bold;
            font-style: italic;
            font-size: min(vw(35), 35px);
            line-height: 1.7;

            span {
              margin-top: min(vw(35), 35px);
              font-style: unset;
              display: block;
              font-size: min(vw(24), 24px);
            }
          }
        }
      }
    }
  }

  .e8ge {
    padding-right: min(vw(60), 60px);
    padding-top: min(vw(60), 60px);

    li {
      &+li {
        margin-top: min(vw(35), 35px);
      }

      .title {
        color: #60A015;
        font-weight: bold;

        span {
          margin-right: min(vw(12), 12px);
          font-weight: bold;
          font-size: min(vw(36), 36px);
        }

        font-size:min(vw(24), 24px);
      }

      .paragraph002 {
        margin-top: min(vw(20), 20px);

        p {
          line-height: 1.5;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .nngn .ign .stafftalk001 {
    width: laptopVw(660);
  }

  .nngn h2 {
    margin-top: laptopVw(30);
  }
}

@media screen and (max-width: 1450px) {}

@media screen and (max-width: 750px) {
  .nngn .ign {
    margin-top: spVw(-30);
    margin-bottom: spVw(-30);
  }

  .nngn .stafftalk002 {
    margin-top: spVw(-60);
  }

  .nngn h2 {
    font-size: spVw(46);
  }

  .a0gg3.ds9dg {
    flex-wrap: wrap;
  }

  .a0gg3.ds9dg .n93:nth-child(1) {
    width: 100%;

    img {
      width: 80%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .a0gg3.ds9dg .n93:nth-child(1) .enn p {
    writing-mode: unset;
  }

  .a0gg3.ds9dg .n93:nth-child(2) {
    width: 100%;
  }

  .a0gg3.ds9dg .n93:nth-child(2) .d8g4d {
    padding-right: spVw(20);
    padding-left: spVw(20);
    border-bottom: spVw(6) solid #82FF00;
  }

  .a0gg3.ds9dg .e8ge {
    padding-right: spVw(20);
    padding-left: spVw(20);

  }

  .a0gg3.ds9dg .n93:nth-child(2) .d8g4d p:nth-child(2) {
    font-size: spVw(34);
  }

  .a0gg3.ds9dg .n93:nth-child(2) .d8g4d p:nth-child(2) span {
    font-size: spVw(24);
  }
  .a0gg3.ds9dg .n93:nth-child(2) .d8g4d{
    padding-bottom: spVw(36);
  }
  .a0gg3.ds9dg .e8ge{
    padding-top: spVw(36);
    padding-bottom: spVw(36);
  }
  .a0gg3.ds9dg .e8ge li .title {
    font-size: spVw(34);
    line-height: 1.4;
    span{
      font-size:spVw(36);
      margin-right: spVw(12);
    }
  }
  .a0gg3.ds9dg .e8ge li + li{
    margin-top: spVw(35);
  }
  .a0gg3.ds9dg .e8ge li .paragraph002{
    margin-top: spVw(24);
  }
}