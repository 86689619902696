@use "../abstracts"as *;


.smf9a {
  .entry001 {
    position: absolute;
    bottom: 50%;
    left: min(vw(840), 840px);
    width: min(vw(600), 600px);
    transform: translateY(50%);
  }

}

.contactform {
  .submit {
    margin-top: min(vw(60), 60px);
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    border-radius: 999px;
    margin-left: auto;
    margin-right: auto;

    input {
      padding: 12px 0;
      font-weight: bold;
      width: 100%;
      border: unset;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  li {
    display: flex;
    align-items: center;

    .short {
      input {
        max-width: min(vw(470), 470px);
      }
    }

    &+li {
      margin-top: min(vw(35), 35px);
    }

    .title {
      width: 220px;
      text-align: center;
      font-size: min(vw(18), 18px);
      line-height: 1.7;

      &+div {
        width: calc(100% - 220px);
      }
    }

    .checkBox {
      background-color: #fff;
      border-radius: 999px;
      padding: min(vw(15), 15px);
      padding-left: min(vw(35), 35px);
      padding-right: min(vw(35), 35px);
    }

    label {
      display: inline-flex;
      align-items: center;
      gap: 4px;

      &+label {
        margin-left: min(vw(25), 25px);
      }

      input {
        display: inline-block;
        width: fit-content;
      }
    }

    input,
    textarea,
    select {
      padding: min(vw(15), 15px);
      border: unset;
      border-radius: 999px;
      width: 100%;
    }

    textarea {
      border-radius: min(vw(30), 30px);
    }
  }
}

.bone {
  display: flex;
  gap: min(vw(24), 24px);

  select {
    width: 140px !important;
  }
}

.banner_top.hack002 {
  padding-top: min(vw(120),120px);
  padding-bottom: min(vw(120),120px);
  text-align: center;
  &:after{
    display: none;
  }

  h3 {
    font-size: min(vw(51), 51px);
    font-weight: bold;
    font-style: italic;
  }

  p {
    font-size: min(vw(24), 24px);
    line-height: 2;
    letter-spacing: 0.05em;
  }
}

@media screen and (max-width: 1600px) {
  .contactform li .title {
    width: 220px;
    font-size: laptopVw(18);
}
.contactform li input, .contactform li textarea, .contactform li select {
  padding: laptopVw(15);
}
}

@media screen and (max-width: 1450px) {}

@media screen and (max-width: 750px) {
  .contactform li{
    flex-wrap: wrap;
  }
  .contactform li .title {
    width:100%;
    text-align: left;
    font-size:spVw(32);
    margin-bottom: spVw(10);
}
  .contactform li .title + div {
    width:100%;
}
.contactform li .short input{
  max-width: spVw(320);
}
.contactform li input, .contactform li textarea, .contactform li select{
  padding: spVw(24);
}
.bone {
  flex-wrap: wrap;
  gap: min(1.25vw, 24px);
  & > div{
    width: 100%;
    max-width: spVw(400);
  }
}
.contactform li input, .contactform li textarea, .contactform li select {
  padding: spVw(24);
}
.bone select{
  width: 100%!important;
}
.contactform li .checkBox{
  padding: spVw(24);
  padding-left: spVw(32);
  padding-right: spVw(32);
}
.contactform li + li{
  margin-top: spVw(46);
}
.smf9a .entry001{
  width: spVw(340);
}
.contactform .submit{
  margin-top: spVw(62);
}
.banner_top.hack002 h3 {
  font-size:spVw(34);
}
.banner_top.hack002 p{
  font-size:spVw(24);
}
}