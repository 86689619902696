@use "../abstracts"as *;
#menuBtn{
  display: none;
  position: fixed;
  top:min(vw(40),40px);
  right: min(vw(40),40px);
  width: min(vw(60),60px);
  height: min(vw(60),60px);
  background-color: #fff;
  border:unset;
  z-index: 11;
  &.act{
    span{
      width: 70%;
      &:nth-child(1){
        transform: translateY(calc(50%)) translateX(-50%) rotate(45deg);
      }
      &:nth-child(2){
        transform:translateY(calc(50%)) translateX(-50%) rotate(-45deg);
      }
    }
  }
  span{
    transition: .4s;
    position: absolute;
    top:50%;
    left:50%;
    width: 80%;
    height: 2px;
    background-color: #000;
    &:nth-child(1){
      transform: translateY(calc(50% - min(vw(-7),-7px))) translateX(-50%);
    }
    &:nth-child(2){
      transform:translateY(calc(50% - min(vw(7),7px))) translateX(-50%);
    }
  }
}
#menu{
  display: none;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(#fff,0.9);
  nav{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ul{
    height: fit-content;
    li{
      &+li{
        margin-top: min(vw(24),24px);
      }
      a{
        display: block;
        font-size:min(vw(24),24px);
        color:#000;
        font-weight: bold;
        text-decoration: unset;
      }
    }
  }
}

header{
  margin-top: min(vw(30),30px);
  overflow: hidden;
  h1{
    a{
      color:#000;
      text-decoration: unset;
      img{
        width: min(vw(240),240px);
        height: auto;
      }
      span{
        display: inline-block;
        font-weight: bold;
        font-size:min(vw(24),24px);
        transform: translateY(min(vw(-15),-15px));
        margin-left: min(vw(40),40px);
        font-style: italic;
      }
    }
  }
  nav{
    position: relative;
    &:after{
      content:"";
      display: block;
      position: absolute;
      top:0;
      left:min(vw(100),-100px);
      width: 100vw;
      height: 100%;
      background-color: #FFFF00;
      z-index: 1;
      transform: skewX(-10deg)
    }
    ul{
      display: flex;
      gap:min(vw(40),40px);
      padding: min(vw(10),10px) 0;
      position: relative;
      z-index: 2;
      li{
        a{
          color:#000;
          text-decoration: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {

}

@media screen and (max-width: 1600px) {

}

@media screen and (max-width: 750px) {
  #menuBtn{
    display: block;
    width: spVw(80);
    height: spVw(80);
  }
  header{
    padding-top: spVw(20);
  }
  header nav{
    display: none;
  }
  header h1 a{
    display: flex;
    align-items: center;
  }
  header h1 a img{
    width: spVw(200);
  }
  header h1 a span{
    font-size:spVw(24);
    transform: unset;
  }
  #menuBtn span:nth-child(1){
    transform: translateY(calc(50% - spVw(-10))) translateX(-50%);
  }
  #menuBtn span:nth-child(2){
    transform: translateY(calc(50% - spVw(10))) translateX(-50%);
  }
  #menu ul li a{
    font-size:spVw(28);
  }
  #menu ul li + li{
    margin-top: spVw(32);
  }
}