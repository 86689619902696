@use "../abstracts"as *;

%gap {
    &.gap-0 {
        gap: 0;
    }

    &.gap-25 {
        gap: min(vw(25), 25px);
    }

    &.gap-50 {
        gap: min(vw(50), 50px);
    }
}



.grid {
    display: grid;

    :where(&) {
        grid-template-columns: 1fr 1fr;
        gap: min(vw(20), 20px);
    }

    @extend %gap;

    &.cols-2 {
        grid-template-columns: 1fr 1fr;
    }

    &.cols-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    &.cols-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.flex {
    display: flex;

    :where(&) {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &.no-wrap {
        flex-wrap: nowrap;
    }

    &.items-center {
        align-items: center;
    }

    &.items-end {
        align-items: flex-end;
    }

    &.content-center {
        justify-content: center;
    }

    &.content-start {
        justify-content: flex-start;
    }

    @extend %gap;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.container1600 {
    margin-left: auto;
    margin-right: auto;
    width: min(vw(1640), 1640px);
    padding-left: min(vw(20), 20px);
    padding-right: min(vw(20), 20px);
}

.container1380 {
    margin-left: auto;
    margin-right: auto;
    width: min(vw(1420), 1420px);
    padding-left: min(vw(20), 20px);
    padding-right: min(vw(20), 20px);
}

.container1150 {
    margin-left: auto;
    margin-right: auto;
    width: min(vw(1190), 1190px);
    padding-left: min(vw(20), 20px);
    padding-right: min(vw(20), 20px);
}

.container910 {
    margin-left: auto;
    margin-right: auto;
    width: min(vw(950), 950px);
    padding-left: min(vw(20), 20px);
    padding-right: min(vw(20), 20px);
}

.paragraph {
    :where(p) {
        font-size: min(vw(24), 24px);
        line-height: 2.08;
        letter-spacing: 0.1em;

        .yellow {
            color: #C09B16;
        }
    }
}

.paragraph002 {
    :where(p) {
        font-size: min(vw(16), 16px);
        line-height: 2.08;
        letter-spacing: 0.1em;

        .yellow {
            color: #C09B16;
        }
    }
}
.paragraph003 {
    :where(p) {
        font-size: min(vw(21), 21px);
        line-height: 2.08;
        // letter-spacing: 0.1em;

        .yellow {
            color: #C09B16;
        }
    }
}

.relative {
    display: inline-block;
    position: relative;
}

.line-height-0 {
    line-height: 0;
}

.mt10 {
    margin-top: min(vw(10), 10px);
}

.mt25 {
    margin-top: min(vw(25), 25px);
}

.mt50 {
    margin-top: min(vw(50), 50px);
}

.mt60 {
    margin-top: min(vw(60), 60px);
}

.mt80 {
    margin-top: min(vw(80), 80px);
}

.mt120 {
    margin-top: min(vw(120), 120px);
}

.mt150 {
    margin-top: min(vw(150), 150px);
}

.mt200 {
    margin-top: min(vw(200), 200px);
}

.mt250 {
    margin-top: min(vw(250), 250px);
}

.mt300 {
    margin-top: min(vw(300), 300px);
}

.mt350 {
    margin-top: min(vw(350), 350px);
}



@media screen and (max-width: 1600px) {
    %gap-lattop {
        &.gap-25 {
            gap: laptopVw(25);
        }

        &.gap-50 {
            gap: laptopVw(50);
        }
    }

    .grid {
        :where(&) {
            gap: laptopVw(20);
        }

        @extend %gap-lattop;
    }



    .container1600 {
        max-width: 100%;
        width: laptopVw(1640);
        padding-left: laptopVw(20);
        padding-right: laptopVw(20);
    }

    .container1380 {
        max-width: 100%;
        width: laptopVw(1420);
        padding-left: laptopVw(20);
        padding-right: laptopVw(20);
    }

    .container1150 {
        width: laptopVw(1190);
        padding-left: laptopVw(20);
        padding-right: laptopVw(20);
    }

    .container910 {
        width: laptopVw(950);
        padding-left: laptopVw(20);
        padding-right: laptopVw(20);
    }

    .paragraph {
        :where(p) {
            font-size: laptopVw(18);
            line-height: 2.4;

            .yellow {
                font-size: laptopVw(24);
            }
        }
    }

    .paragraph002 {
        :where(p) {
            font-size: laptopVw(14);
        }
    }
    .paragraph003 {
        :where(p) {
            font-size: laptopVw(21);
        }
    }

}


@media screen and (max-width: 1450px) {}

@media screen and (max-width:750px) {
    .pcBr {
        display: none;
    }

    .container1600 {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .container1380 {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .container1150 {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .container910 {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .paragraph {
        p {
            font-size: spVw(24);
            line-height: 2.2;

            .yellow {
                font-size: spVw(32);
            }
        }
    }

    .paragraph002 {
        :where(p) {
            font-size: spVw(24);
        }
    }
    .paragraph003 {
        :where(p) {
            font-size: spVw(24);
        }
    }

    .mt10 {
        margin-top: spVw(10);
    }

    .mt25 {
        margin-top: spVw(25);
    }

    .mt50 {
        margin-top: spVw(40);
    }

    .mt60 {
        margin-top: spVw(50);
    }

    .mt80 {
        margin-top: spVw(60);
    }

    .mt120 {
        margin-top: spVw(80);
    }

    .mt150 {
        margin-top: spVw(100);
    }

    .mt200 {
        margin-top: spVw(120);
    }

    .mt250 {
        margin-top: spVw(150);
    }

    .mt300 {
        margin-top: spVw(200);
    }

    .mt350 {
        margin-top: spVw(250);
    }

}