@use "../abstracts"as *;

.gae8{
  background-image: url(../images/corporate002.png);
  background-repeat: no-repeat;
  background-position: 50%;
  padding-top: min(vw(130),130px);
  padding-bottom: min(vw(130),130px);
}

.nag9d{
  margin: 0 auto;
  background-color: rgba(#fff,.8);
  border-radius: min(vw(50),50px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: min(vw(621),621px);
  height: min(vw(621),621px);
  img{
    width: min(vw(315.5),315.5px);
  }
  p{
    font-size:min(vw(30),30px);
    font-style: italic;
    margin-top: min(vw(100),100px);
  }
}


.nsdgd9{
  .f3m3{
    background-color: #fff;
    border-radius:0 min(vw(50),50px) min(vw(50),50px) 0 ;
    padding-top: min(vw(60),60px);
    padding-bottom: min(vw(100),100px);
    position: relative;
    &:after{
      content:"";
      display: block;
      position: absolute;
      top:0;
      left:0;
      height: 100%;
      width: 500px;
      background-color: #fff;
      transform: translateX(-100%);
    }
    h2{
      text-align: right;
      margin-right: min(vw(80),80px);
      position: relative;
      font-size:min(vw(40),40px);
      font-weight: bold;
      font-style: italic;
      padding-bottom: min(vw(26),26px);
      padding-right: min(vw(16),16px);
      span{
        font-style: italic;
        font-size:min(vw(30),30px);
      }
      &:after{
        content:"";
        position: absolute;
        display: block;
        right: 0;
        bottom:0;
        width: 100%;
        height: min(vw(6),6px);
        background-color: #82FF00;
      }
    }
  }
}
.snne{
  li{
    & + li{
      margin-top: min(vw(60),60px);
    }
    p{
      &.title{
        font-weight: bold;
        color:#60A015;
        font-size:min(vw(24),24px);
        margin-bottom: min(vw(12),12px);
      }
      letter-spacing: .05em;
      font-size:min(vw(18),18px);
      line-height: 1.5;
      &.indent{
        text-indent: -2.7em;
        margin-left: 2.7em;
      }
    }
  }
}


.nrnnr{
  li{
    display: flex;
    gap:min(vw(50),50px);
    & + li{
      margin-top: min(vw(40),40px);
    }
    p{
      font-size:min(vw(18),18px);
      line-height: 1.7;
        &:nth-child(1){
        display: flex;
        span{
          font-size:min(vw(18),18px);
          display: block;
          font-weight: bold;
          color:#60A015;
          &:nth-child(2){
            text-align: right;
            width: min(vw(45),45px);
          }
        }
      }
    }
  }
}
.map{
  margin-right: min(4.1666666667vw, 80px);
  iframe{
    width: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .nsdgd9 .f3m3 h2{
    font-size:laptopVw(40);
  }
  .nsdgd9 .f3m3 h2 span{
    font-size:laptopVw(30);
  }
  .snne li p.title{
    font-size:laptopVw(24);
  }
  .snne li p{
    font-size:laptopVw(18);
  }
  .nrnnr li p{
    font-size:laptopVw(18);
  }
  .nrnnr li p:nth-child(1) span{
    font-size:laptopVw(18);
  }
  .nrnnr li p:nth-child(1) span:nth-child(2){
    width:laptopVw(50);
  }
  .nsdgd9 .f3m3{
    padding-bottom: laptopVw(100);
    padding-top: laptopVw(60);
  }

}

@media screen and (max-width: 1450px) {
  .nag9d{
    width: laptopVw(600);
    height: laptopVw(600);
    img{
      width: laptopVw(315);
    }
    p{
      font-size:laptopVw(30);
    }
  }
}

@media screen and (max-width: 750px) {
  .nag9d{
    width: spVw(600);
    height: spVw(600);
    img{
      width: spVw(315);
    }
    p{
      font-size:spVw(30);
    }
  }
  .nsdgd9 .f3m3 h2{
    font-size:spVw(38);
    span{
      font-size:spVw(30);
    }
  }
  .snne li p.title{
    font-size:spVw(28);
  }
  .snne li p{
    font-size:spVw(28);
  }
  .snne li + li{
    margin-top: spVw(60);
  }
  .nrnnr li + li{
    margin-top: spVw(50);
  }
  .nrnnr li{
    flex-wrap: wrap;
    gap:spVw(10);
    p{
      font-size:spVw(28);
    }
  }
  .nrnnr li p:nth-child(1){
    width: 100%;
    span{
      font-size:spVw(28);
    }
  }
  .nrnnr li p:nth-child(1) span:nth-child(2){
    width: unset;
    margin-left: 10px;
  }
  .meeem{
    margin-right: min(4.1666666667vw, 80px);
    font-size:spVw(28);
  }
  .nsdgd9 .f3m3 {
    padding-bottom: spVw(68);
    padding-top: spVw(48);
}
.nsdgd9 .f3m3 h2{
  padding-bottom: spVw(26);
}
.nsdgd9 .f3m3 h2:after{
  height: spVw(10);
}
}