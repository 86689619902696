@use "../abstracts"as *;

.eme0f {
  h2 {
    text-align: right;
    margin-bottom: -3px;

    img {
      width: min(vw(656), 656px);
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  gap: min(vw(32), 32px);

  a {
    display: block;
    font-size: min(vw(50), 50px);
    font-weight: bold;
    font-style: italic;
    color: #000;
    text-decoration: unset;
  }
}

.newsBox {
  .nent {
    time {
      font-size: min(vw(24), 24px);
      font-weight: bold;
    }

    p {
      margin-top: min(vw(10), 10px);
      font-size: min(vw(30), 30px);
      line-height: 1.5;
    }
  }
}

.newspost {
  border-top: 1px solid #000;
  padding-top: min(vw(27), 27px);
  margin-top: min(vw(12), 12px);

  p {
    line-height: 1.7;

    &+p {
      margin-top: min(vw(24), 24px);
    }
  }
}

.news-back{
  font-weight: bold;
  text-decoration: unset;
  width: fit-content;
  display: block;
  font-size:min(vw(50),50px);
  margin-top: min(vw(32),32px);
  margin-left: auto;
  color:#000;
  margin-right: auto;
  font-style: italic;
}

@media screen and (max-width: 1600px) {
  .eme0f {
    h2 {
      img {
        width: laptopVw(656);
      }
    }
  }

  .newsBox {
    .nent {
      time {
        font-size: laptopVw(24);
      }

      p {
        margin-top: laptopVw(10);
        font-size: laptopVw(30);
      }
    }
  }

  .newspost {
    padding-top: laptopVw(27);
    margin-top: laptopVw(12);

    p {
      &+p {
        margin-top: laptopVw(24);
      }
    }
  }
  .news-back{
    font-size:laptopVw(50);
    margin-top: laptopVw(32);
  }
  
}

@media screen and (max-width: 1450px) {}

@media screen and (max-width: 750px) {
  .eme0f {
    h2 {
      img {
        width: spVw(456);
      }
    }
  }

  .pagination {
    gap: spVw(32);
  }

  .pagination a {
    font-size: spVw(46);
  }

  .newsBox{
    .nent{
      time{
        font-size:spVw(28);
      }
      p{
        margin-top:spVw(10);
        font-size:spVw(36);
      }
    }
  }
    .newspost{
        padding-top: spVw(27);
        margin-top: spVw(12);
        p{
          & + p{
            margin-top: spVw(24);
          }
        }
      }
      .news-back{
        font-size:spVw(50);
        margin-top: spVw(32);
      }
      
}