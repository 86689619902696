@use "../abstracts"as *;

.md9g{
  position: relative;
  background-color: #fff;
  border-radius: min(vw(60),60px);
  padding-top: min(vw(100),100px);
  padding-left: min(vw(60),60px);
  padding-right: min(vw(60),60px);
  padding-bottom: min(vw(100),100px);
  .environment001{
    position: absolute;
    top:0;
    left:0;
    width: min(vw(373),373px);
    border: 10px solid #82FF00;
    border-radius: 50%;
    transform: translateY(-30%) translateX(-30%);
  }
  .environment003{
    position: absolute;
    top:0;
    right:min(vw(40),40px);
    width: min(vw(181),181px);
    transform: translateY(-50%);
  }
  h3{
    color:#000;
    font-size:min(vw(42),42px);
    position: relative;
    // text-align: right;
    // padding-right: min(vw(100),100px);
    padding-bottom: min(vw(20),20px);
    margin-left: min(vw(260),260px);
    &:after{
      content:"";
      display: block;
      position: absolute;
      bottom:0;
      left:0;
      width: 130%;
      height: 6px;
      background-color: #82FF00;
    }
  }
  h4{
    position: relative;
    text-align: center;
    span{
      display: inline;
      width: fit-content;
      font-weight: bold;
      margin-left: auto;
      margin-right: auto;
      font-size:min(vw(40),40px);
      letter-spacing: .1em;
      background:linear-gradient(transparent 60%, #FFFF00 60%);
    }
   
  }
}

.as8d{
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
  & > div{
    &:nth-child(1){
      width: min(vw(310),310px);
      
    }
    &:nth-child(2){
      width: calc(100% - min(vw(310),310px));
    }
  }
}
.sid{
  width: min(vw(260),260px);
  height: min(vw(260),260px);
  border-radius: 15%;
  background-color: #FFFF00;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span{
    font-size:min(vw(36),36px);
    display: block;
    font-weight: bold;
    width: 80%;
    text-align: center;
    padding-top: min(vw(15),15px);
    &:nth-child(1){
      border-bottom:3px solid #000;
      padding-top: 0;
      padding-bottom: min(vw(15),15px);
    }
  }
}
.g2ie{
  & + .g2ie{
    margin-top: min(vw(30),30px);
    padding-top: min(vw(30),30px);
    border-top:2px solid #000;
  }
  h5{
    font-weight: bold;
    font-size:min(vw(24),24px);
    margin-bottom: min(vw(15),15px);
  }
}
@media screen and (max-width: 1600px) {}

@media screen and (max-width: 1450px) {}

@media screen and (max-width: 750px) {
  .md9g{
    padding-top: spVw(80);
    padding-bottom: spVw(80);
  }
  .md9g h3{
    font-size:spVw(42);
    padding-bottom: spVw(20);
  }
  .md9g h4{
    span{
      font-size:spVw(38);
      display: inline;

    }
  }
  .md9g .environment003{
    width: spVw(160);
  }
  .as8d{
    flex-wrap: wrap;
    & > div{
      &:nth-child(1){
        width: 100%;
      }
      &:nth-child(2){
        width: 100%;
        margin-top: spVw(40);
      }
    }
  }
  .sid{
    width: spVw(240);
    margin-left: auto;
    margin-right: auto;
    span{
      font-size:spVw(32);
      &:nth-child(1){
        border-bottom:2px solid #000;
      }
    }
    height: spVw(200);
  }
  .g2ie h5{
    font-size:spVw(32);
  }
  .g2ie + .g2ie{
    border-top:1px solid #000;
    margin-top: spVw(32);
    padding-top: spVw(32);
  }
  .md9g .environment001{
    width: spVw(250);
    border:5px solid #82FF00;
  }
}

