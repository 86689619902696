@use "../abstracts"as *;


.smf9a {
  .detail002 {
    position: absolute;
    bottom: 50%;
    left: min(vw(840), 840px);
    width: min(vw(600), 600px);
    transform: translateY(50%);
  }

}
.enn9s{
li{
    details{
      cursor: pointer;
      padding-bottom: min(vw(30),30px);
      padding-left: min(vw(100),100px);
      padding-right: min(vw(120),120px);
      background-color: #fff;
      padding-top: min(vw(30),30px);
      border-radius: min(vw(45),45px);
      &[open]{
        summary{
          &:after{
            background-image: url(../images/circleTop.png);
          }
        }
      }
      summary{
        position: relative;
        font-size:min(vw(36),36px);
        font-style: italic;
        font-weight: bold;
        
        span{
          font-style: italic;
          font-size:min(vw(24),24px);
        }
        &:after{
          content:"";
          position: absolute;
          display: block;
          top:50%;
          right: min(vw(-40),-40px);
          transform: translateY(-50%);
          width: min(vw(40),40px);
          height: min(vw(40),40px);
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: cover;
          background-image: url(../images/circleBottom.png);
        }
      }
      ol{
        margin-top: min(vw(40),40px);
        li{
          list-style-type: none;
          display: flex;
          padding-top: min(vw(20),20px);
          padding-bottom: min(vw(15),15px);
          border-bottom:2px solid #82FF00;
          p{
            font-size:min(vw(18),18px);
            width:calc(100% - min(vw(150),150px));
            line-height: 1.7;
            &:nth-child(1){
              text-align: center;
              font-weight: bold;
              color:#60A015;
              width: min(vw(150),150px);
            }
          }

        }
      }
    }
    & + li{
      margin-top: min(vw(20),20px);
 
    }
  }
}
.banner_top.hack001{
  a{
    width: min(vw(885),885px);
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
  }
  &:after{
    display: none;
  }
}

@media screen and (max-width: 1600px) {
  .enn9s > li + li {
    margin-top: laptopVw(30);
  }
}

@media screen and (max-width: 1450px) {}

@media screen and (max-width: 750px) {

.smf9a .detail002{
  width: spVw(340);
}

.enn9s li details summary {
  font-size:spVw(36);
  span{
    display: block;
    font-size:spVw(24);
    margin-top: spVw(20);
  }
}
.enn9s li details{
  padding-top: spVw(26);
  padding-bottom: spVw(26);
  padding-right: spVw(60);
}
.enn9s li details summary:after{
  width: spVw(40);
  height: spVw(40);
  right: spVw(-40);
}
.enn9s > li + li {
  margin-top: spVw(30);
}
.enn9s li details ol li {
  flex-wrap: wrap;
  padding-top:  spVw(20);
  padding-bottom: spVw(20);
  border-bottom: 1px solid #82FF00;
  p{
    font-size:spVw(26);
  }
}
.enn9s li details ol li p:nth-child(1){
  width: 100%;
  text-align: left;
}
.enn9s li details ol li p:nth-child(2){
  width: 100%;
}
.banner_top.hack001 a{
  width: spVw(520);
}
}