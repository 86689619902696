// Breakpoints
$mq-breakpoints: (
  "large": 1024px,
  "medium": 768px,
);

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500&family=Noto+Sans+JP:wght@300;400;500;700&family=Open+Sans:ital,wght@0,500;0,700;1,700&display=swap');

// font-family: 'Noto Sans JP', sans-serif;
// font-family: 'Open Sans', sans-serif;
// font-family: 'Noto Serif JP', serif;

// Fonts
$base-font-family: 'Noto Sans JP', sans-serif;

// Colors
$base-text-color: #000;
