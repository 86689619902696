@use "../abstracts"as *;

.message001{
  background-image: url(../images/message001.png);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: contain;
  width: 100%;
  height: min(vw(1085),1085px);
  h3{
    font-size:min(vw(48));
    font-weight: bold;
  }
}
.message002{
  img{
    width: min(vw(508.8),508.8px);
    height: auto;
  }
  & + p{
    font-size:min(vw(16),16px);
    margin-left: min(vw(200),200px);
    line-height: 1.6;
    span{
      font-size:min(vw(12));
    }
  }
}
.message003{
  width: min(vw(1521),1521px);
}
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1450px) {
}

@media screen and (max-width: 750px) {
  .message002 img {
    width: spVw(350);
}
.message002 + p{
  width: 100%;
  font-size:spVw(24);
  margin-left: 0;
  margin-top: spVw(30);
}
.message001{
  height: auto;
  background-position: 100% 0%;
  margin-top: spVw(60);
}
.message001 h3{
  font-size:spVw(38);
}
.message003{
  width: 97%;
}
}