@use "../abstracts"as *;


.smf9a{
  background-color: #FFFF00;
  position: relative;
  height: min(vw(400),400px);
  .ene{
    position: absolute;
    top:min(vw(-75),-75px);
    left:min(vw(170),170px);
    width: min(vw(600),600px);
    height: auto;
  }
  .qa002{
    position: absolute;
    bottom:0;
    left:min(vw(840),840px);
    width: min(vw(600),600px);
    transform: translateY(min(vw(86),86px));
  }

}

.me0e4{
  li{
    background-color: #fff;
    border-radius: min(vw(30),30px);
    padding: min(vw(80),80px);
    .title{
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-style: italic;
      padding-bottom: min(vw(26),26px);
      font-size:min(vw(36),36px);
      border-bottom:2px solid #82FF00;
    }
    .parapgraph002{
      margin-top: min(vw(40),40px);
      p{
        line-height: 2;
      }
    }
    & + li{
      margin-top: min(vw(50),50px);
    }
  }
}

@media screen and (max-width: 1600px) {
  
}

@media screen and (max-width: 1450px) {}

@media screen and (max-width: 750px) {
  .smf9a{
    height: spVw(350);
  }
  .smf9a .ene{
    width:  spVw(300);
    top:0;
  }
  .smf9a .qa002{
    width: spVw(340);
    transform: translateY(spVw(50));
  }
  .me0e4 li{
    padding-top: spVw(46);
    padding-bottom: spVw(46);
  }
  .me0e4 li .title{
    font-size:spVw(42);
    padding-bottom: spVw(32);
  }
  .me0e4 li .parapgraph002 p {
    line-height: 1.7;
}
.me0e4 li + li {
  margin-top: spVw(36);
}
}