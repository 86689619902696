@use "../abstracts" as *;

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

html,
body {
  text-size-adjust: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-family: $base-font-family;
  font-size: 1.6rem;
  line-height: 1;
  color: $base-text-color;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  background-color: #82FF00;
}
