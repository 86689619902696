@use "../abstracts"as *;

.aig9 {
  position: relative;

  .career001 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: min(vw(800), 800px);
    height: auto;
  }

  h2 {
    font-size: min(vw(82), 82px);
    font-weight: bold;
    font-style: italic;
    color: #fff;
  }

  h3 {
    font-size: min(vw(41), 41px);
    font-weight: bold;
    font-style: italic;
    color: #fff;
  }

  h4 {
    font-size: min(vw(25), 25px);
    font-weight: bold;
    font-style: italic;
  }
}

.bfn {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  border-radius: min(vw(30), 30px);
  background-color: #FFFF00;
  padding: min(vw(35), 35px) min(vw(80), 80px);

  p {
    color: #000;
    font-weight: bold;
    font-style: italic;
    font-size: min(vw(45), 45px);
  }
}

.mf9e {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  gap: min(vw(50), 50px) min(vw(90), 90px);

  &>div {
    width: calc(50% - min(vw(45)));

    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(2) {
      order: 1;
    }

    &:nth-child(3) {
      order: 3;
    }

    .sm9f {
      display: flex;

      &:last-child {
        &>div {
          text-align: right;
          border-bottom: unset;
        }
      }

      &>div {
        border-bottom: 2px solid #fff;
        padding-top: min(vw(12));
        padding-bottom: min(vw(12));

        &:nth-child(1) {
          width: calc(100% - 90px);
          padding-right: min(vw(12));
          padding-left: min(vw(12));
        }

        &:nth-child(2) {
          text-align: center;
          border-left: 2px solid #fff;
          width: 90px;

          &.dnkg {
            p {
              font-size: min(vw(29));
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.aein {
  display: flex;
  flex-wrap: wrap;
  gap: min(vw(26), 26px);

  .sge {
    .nsns {
      margin-top: 5px;
      font-size: min(vw(14), 14px);
    }

    width: calc(33.33% - min(vw(20), 20px));

    .box {
      width: 100%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: min(vw(40), 40px);
      text-align: center;
      padding-top: min(vw(40), 40px);
      padding-bottom: min(vw(40), 40px);
      min-height: min(vw(205), 205px);

      p {
        font-weight: bold;

        &:nth-child(1) {
          font-size: min(vw(30), 30px);
          line-height: 1.4;
        }

        &:nth-child(2) {
          margin-top: min(vw(20), 20px);
          font-style: italic;

          span {
            font-weight: bold;
            font-size: min(vw(36), 36px);
            font-style: italic;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {}

@media screen and (max-width: 1450px) {}

@media screen and (max-width: 750px) {
  .mf9e>div {
    width: 100%;
  }

  .mf9e>div:nth-child(1) {
    order: 1;
  }

  .mf9e>div:nth-child(2) {
    order: 2;
  }

  .aig9 h2 {
    font-size: spVw(42);
  }

  .aig9 h3 {
    font-size: spVw(26);
  }

  .bfn p {
    font-size: spVw(36);
  }

  .aig9 h4 {
    font-size: spVw(32);
  }

  .mf9e>div .sm9f {
    align-items: center;
  }

  .mf9e>div .sm9f>div {
    border-bottom: 1px solid #fff;
    padding-top: spVw(12);
    padding-bottom: spVw(12);

    &:nth-child(1) {
      padding-right: spVw(12);
      padding-left: spVw(12);
    }

    p {
      font-size: spVw(22);
    }
  }

  .mf9e>div .sm9f>div:nth-child(2).dnkg p {
    font-size: spVw(36);
  }

  .aig9 .career001 {
    margin-top: spVw(56);
    position: relative;
    width: spVw(600);
  }
  .aein{
    gap:spVw(20) spVw(20);
  }
  .aein .sge{
    width: calc(50% - spVw(10));
  }
  .aein .sge .box{
    min-height: spVw(160);
  }
  .aein .sge .box p:nth-child(1){
    font-size:spVw(24);
  }
  .aein .sge .box p:nth-child(2){
    font-size:spVw(20);
    span{
      font-size:spVw(24);
    }
  }
}

