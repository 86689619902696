@use "../abstracts"as *;
.banner {
  position: fixed;
  right: 0;
  bottom:min(vw(50),50px);
  overflow: hidden;
  z-index: 3;
  a{
    display: block;
    margin-right: min(vw(-23),-23px);
    img{
      width: min(vw(290),290px);
    }
  }
}
.banner_top{
  position: relative;
  z-index: 4;
}
@media screen and (max-width: 1600px) {

}

@media screen and (max-width: 1600px) {

}

@media screen and (max-width: 750px) {
  .banner{
    left:0;
    bottom:0;
    display: flex;
    a{
      width: 50%;
      position: relative;
      background-color: #FFFF00;
      text-align: center;
      padding-top: spVw(24);
      padding-bottom: spVw(24);
      text-decoration: unset;
      margin-right: 0;
      &:nth-child(1){
        &:after{
          content:"採用情報";
          color:#000;
          font-weight: bold;
          font-size:spVw(24);
        }
      }
      &:nth-child(2){
        border-left:2px solid #fff;
        &:after{
          content:"お問い合わせ＆エントリー";
          color:#000;
          font-weight: bold;
          font-size:spVw(24);
        }
      }
      img{
        width: 100%;
        display: none;
      }
    }
  }
}