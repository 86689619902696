@use "../abstracts"as *;


footer{
  padding-top: min(vw(100),100px);
  padding-bottom: min(vw(100),100px);
  background-color: #fff;

.footerLogo{
  width: min(vw(325.5),325.5px);
  margin-left: auto;
  margin-right: auto;
  display: block;
}
nav{
  ul{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:min(vw(30),30px);
    li{
      a{
        display: block;
        color:#000;
        text-decoration: unset;
        font-size:min(vw(17),17px);
      }
    }
  }
}
  .copy{
    display: block;
    text-align: center;
    font-size:min(vw(18),18px);
    margin-top: min(vw(50),50px);
  }
}

@media screen and (max-width: 1450px) {
  footer{
    .container910{
      width: 90%;
      max-width: 910px;
    }
 

  }
}


@media screen and (max-width: 750px) {
  footer .container910{
    width: 100%;
  }
  footer{
    padding-top: spVw(100);
    padding-bottom: spVw(100);
  }
  footer .footerLogo{
    width: spVw(260);
  }
  footer nav ul{
    flex-wrap: wrap;
    justify-content: flex-start;
    gap:15px 0;
    li{
      width: 50%;
      a{
        font-size:spVw(23);
      }
    }
  }
  footer .copy{
    margin-top: spVw(36);
  }
}