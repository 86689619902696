@use "../abstracts"as *;

main {
  overflow: hidden;
}
.gd84{
  background-color: #82FF00;
}
.fv {
  margin-top: min(vw(80), 80px);
  z-index: 3;
  background-color: #fff;
  border-radius: min(vw(30), 30px);
  padding-left: min(vw(60), 60px);
  padding-right: min(vw(100), 100px);
  position: relative;

  img {
    margin-top: min(vw(-40), -40px);
    margin-bottom: min(vw(-40), -40px);
  }

  .text {
    position: absolute;
    left: min(vw(60), 60px);
    bottom: min(vw(70), 70px);
    font-size: min(vw(18), 18px);
    line-height: calc(40 / 18);
  }
}

.index_message {
  width: min(vw(512), 512px);
}

.ng8 {
  font-size: min(vw(65), 65px);
  font-weight: bold;
}

.linkImg001 {
  position: absolute;
  width: 209px;
  height: auto;
  top: 0;
  right: 0;
  transform: translateY(-80%) translateX(110%);
}




.a0gg3 {
  width: 100%;
  background-color: #fff;
  max-width: min(vw(1550), 1550px);
  border-radius:0  min(vw(55), 55px)  min(vw(55), 55px) 0;
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: contain;
  padding-top: min(vw(60), 60px);
  // padding-bottom: min(vw(120), 120px);
  padding-left: min(vw(230), 230px);
  min-height: min(vw(600),600px);
  & + .a0gg3{
    margin-top: min(vw(150),150px);
  }

  .linkImg002 {
    position: absolute;
    top: 0;
    right: 0;
    width: min(vw(180),180px);
    height: auto;
    transform: translateX(calc(100% + min(vw(40),40px)));
  }

  h2 {
    width: fit-content;
    font-weight: bold;
    font-style: italic;
    font-size: min(vw(102), 102px);
    line-height: 1.3;

    span {
      font-weight: bold;
      font-size: min(vw(50), 50px);
      display: block;
    }
  }

  &.index002 {
    background-image: url(../images/index002.png);
  
  }

  &.index003 {
    background-image: url(../images/index003.png);
  }
}


.sliderWrap {
  background-color: #fff;
  padding: min(vw(50),50px) 0;
  position: relative;
    h2{
      position: absolute;
      top:0;
      left:min(vw(160),160px);
      z-index: 2;
      transform: translateY(-50%);
      .index-staffTalk{
        width: min(vw(300));
      }
    }
    .swiper-slide {
      display: flex;
      gap:min(vw(50),50px);
      text-decoration: unset;
      color:#000;
      & > div{
        width: 50%;
        &:nth-child(1){}
        &:nth-child(2){
          padding-top: min(vw(40),40px);
          // padding-right: min(vw(50),50px);
          max-width: min(vw(400),400px);
          .paragraph{
            margin-top: min(vw(50),50px);
            p{
              font-weight: bold;
              font-size:min(vw(34),34px);
              letter-spacing: 0.01em;
              font-feature-settings: "palt";
              line-height: 1.4;
            }
          }
        }
        .dgi9{
          .number{
            font-size:min(vw(102),102px);
            font-style: italic;
            font-weight: bold;
            & + p{
              text-align: right;
              line-height: 1.3;
            }
          }
        }
        p{
          display: flex;
          align-items: center;
          font-size:min(vw(18),18px);
        }
      }
    }
    .swiper-button-next, .swiper-button-prev{
      top:50%;
      width: min(vw(60),60px);
      height: min(vw(60),60px);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      &:after{
        content:'';
      }
    }
    .swiper-button-next{
      right:min(vw(280),280px);
      background-image: url(../images/next.png);
    }
    .swiper-button-prev{
      left:min(vw(280),280px);
      background-image: url(../images/prev.png);
      
    }
}



.kh7g{
  padding-top: min(vw(250),250px);
}
.hatena{
  width: min(vw(265.5),265.5px);
  margin-right: min(vw(50),50px);
}
.whatsrokusha{
  width: min(vw(461.5),461.5px);
}
.index010{
  width: min(vw(413),413px);
  margin-top: min(vw(20),20px);
}
.d9gg{
  margin-right: min(vw(20),20px);
  .linkImg003{
    position: absolute;
    // opacity: 0;
    right: 0;
    top:min(vw(-40),-40px);
    transform: translateX(calc(100% + min(vw(15),15px)));
    width: min(vw(200),200px);
  }
  .paragraph{
    p{
      font-size:min(vw(24),24px);
      line-height: calc(50/24) + em;
      letter-spacing: calc(100 / 1000);
    }
  }
}
.noe{
  margin-left: min(vw(75),75px);
}

.enie{
  .faq{
    width: min(vw(604.5),604.5px);
  }
  .linkImg004{
    position: absolute;
    // opacity: 0;
    right: 0;
    top:min(vw(-40),-40px);
    transform: translateX(calc(100% + min(vw(15),15px)));
    width: min(vw(200),200px);
  }

  .paragraph{
    p{
      font-size:min(vw(24),24px);
      line-height: calc(50/24) + em;
      letter-spacing: calc(100 / 1000);
    }
  }

}

.newsBox{
  background-color: #fff;
  border-radius: min(vw(45),45px);
  padding: min(vw(80),80px);
  padding-bottom: min(vw(100),100px);
  h2{
    text-align: center;
    display: flex;
    align-items: center;
    gap:min(vw(10),10px);
    justify-content: center;
    font-size:min(vw(83),83px);
    font-weight: bold;
    span{
      font-weight: bold;
      font-size:min(vw(33),33px);
    }
  }
  ul{
    li{
      border-bottom:1px solid #000;
      a{
        padding: min(vw(20),20px) min(vw(17),17px) min(vw(14),14px);
        display: flex;
        align-items: center;
        text-decoration: unset;
        gap:min(vw(70),70px);
        span{
          color:#000;
          font-size:min(vw(24),24px);
        }
      }
    }
  }
}

.linksBox{
  background-color: #FFFF00;
  position: relative;
  &.line{
    &:after{
      content:"";
      display: block;
      width: 1px;
      height: 70%;
      background-color: #000;
      position: absolute;
      top:50%;
      left:50%;
      transform: translateY(-50%) translateX(-50%) rotate(20deg);
    }
  }
  a{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: min(vw(120));
    padding-bottom: min(vw(120));
  }
  .index-recruit{
    width: min(vw(425),425px);
  }
  .index-contact_entry{
    width: min(vw(461),461px);
  
  }
}

.dlgl{
  padding-top: min(vw(130));
  padding-bottom: min(vw(130));
  background-color: #fff;
  border-bottom:1px solid #000;
  p{
    font-size:min(vw(16),16px);
    letter-spacing: calc(100/1000) + em;
    font-weight: 500;
  }
  .rokusyalogo{
    width: min(vw(332),332px);
    height: auto;
  }
}

@media screen and (max-width: 1600px) {
  .a0gg3 {
    max-width: laptopVw(1200);
    min-height: laptopVw(430);
  }
  .dlgl{
    .container1150{
      max-width:800px;
    }
  }
}

@media screen and (max-width: 1450px) {
}

@media screen and (max-width: 750px) {
  .fv{
    margin-top: spVw(60);
  }
  .fv img{
    margin-top: spVw(-20);
    margin-bottom: spVw(160);
  }
  .fv .text{
    line-height:1.7;
    letter-spacing: 0.05em;
    width: 88%;
  }
  .index_message{
    width: spVw(340);
  }
  .ng8 {
    font-size:spVw(40);
}
.linkImg001{
  width: spVw(200);
  transform: translate(30%, -100%);
}
.a0gg3{
  width: 100%;
  max-width: 95%;
  min-height: spVw(350);
  &.index002{
    .paragraph{
      margin-top: spVw(75);
    }
  }
  .paragraph{
    margin-top: spVw(15);
    p{
      line-height: 2;
    }
  }
}
.a0gg3 .linkImg002{
  width: spVw(160);
}
.sliderWrap .swiper-slide {
  gap: spVw(20);
  flex-wrap: wrap;
}
.sliderWrap .swiper-slide > div{
  width: 100%;
}
.sliderWrap .swiper-slide > div p{
  flex-wrap: wrap;
}
.sliderWrap .swiper-slide > div:nth-child(2) {
  padding-top: min(2.0833333333vw, 40px);
  max-width:unset;
  padding-left: spVw(40);
  padding-right: spVw(40);
}
.sliderWrap .swiper-button-next, .sliderWrap .swiper-button-prev{
  width: spVw(60);
  height: spVw(60);
}
.whatsrokusha {
  width:spVw(380);
}
.d9gg .linkImg003{
  width: spVw(160);
  transform: translateX(calc(100% + spVw(-90)));
}
.d9gg .paragraph p{
  font-size:spVw(24);
}
.index010{
  display: none;
}
.noe{
  .mt150{
    margin-top: spVw(50);
  }
}
.sliderWrap h2 .index-staffTalk {
  width: spVw(200);
}
.enie .linkImg004 {
  top: spVw(-60);
  transform: translateX(calc(100% + spVw(35)));
  width: spVw(160);
}
.newsBox{
  padding: spVw(60) spVw(20);
}
.newsBox h2{
  font-size:spVw(65);
  gap:spVw(20);
  span{
    font-size:spVw(35);
  }
}
.newsBox ul li {
  &:nth-child(1){
    a{
      padding-top: spVw(0);
    }
  }
}
.newsBox ul li a{
  flex-wrap: wrap;
  gap:spVw(10);
  padding: spVw(30) spVw(10);
  span{
    display: block;
    font-size:spVw(26);
  }
}
.dlgl{
  padding-top: spVw(60);
  padding-bottom: spVw(60);
}
}